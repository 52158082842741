import axios from "axios";
import vue from "@/main";

const http = axios.create({
    // baseURL: 'http://127.0.0.1:8221/'
    baseURL: 'https://co.youshan.co/api/'
})

http.interceptors.request.use(config => {
    if (config.url !== 'login') {
        config.headers['token'] = localStorage.getItem('token') || ''
    }
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config
}, error => {
    return Promise.reject(error)
})

http.interceptors.response.use(response => {
    let data = response.data
    if (response.config.url !== 'login') {
        if (data.code === 401) {
            // vue.$toast.fail({message: '登录信息过期，请重新登录'})
            vue.$bus.$emit('login')
            return Promise.reject(401)
        } else if (data.code === 403) {
            console.log(response.config.url)
            if (response.config.method === 'get') {
                vue.$bus.$emit('login')
            } else {
                vue.$toast.fail('没有权限')
            }
            return Promise.reject(403)
        }
    }
    if (response.config.url.startsWith('mock')) {
        return response
    }
    return data
}, error => {
    return Promise.reject(error)
})

export default http
