import Vue from 'vue'
import App from './App.vue'
// import axios from 'axios'
import VueAxios from 'vue-axios'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueRouter from 'vue-router'
import http from '@/http'

import router from "@/router";

import store from "@/store";

Vue.use(VueRouter)
Vue.use(Vant);

Vue.config.productionTip = false

Vue.use(VueAxios, http)

const vue = new Vue({
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  render: h => h(App),
  router: router,
  store
})
vue.$mount('#app')

export default vue
