import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        profile: {}
    },
    mutations: {
        setProfile(state, payload) {
            state.profile = payload
        }
    },
    getters: {
        getProfile: state => state.profile
    }
})

export default store
