<template>
  <div id="app">
    <router-view />
    <van-tabbar safe-area-inset-bottom route v-show="['/', '/order', '/dataSheet', '/settings', '/mock'].includes($route.path)">
      <van-tabbar-item icon="home-o" replace to="/">设备</van-tabbar-item>
      <van-tabbar-item v-if="permissions.includes('view_all_order')" icon="goods-collect-o" replace to="/order">订单</van-tabbar-item>
      <van-tabbar-item v-if="permissions.includes('view_data')" icon="chart-trending-o" replace to="/dataSheet">数据</van-tabbar-item>
      <van-tabbar-item icon="setting-o" replace to="/settings">设置</van-tabbar-item>
      <van-tabbar-item v-if="permissions.includes('mock_order')" icon="certificate" replace to="/mock">测试</van-tabbar-item>
    </van-tabbar>

    <van-dialog v-model="showLogin" title="用户登录" :show-confirm-button="true" confirm-button-text="登录" :close-on-click-overlay="false" :before-close="doLogin">
      <div style="background-color: wheat">
        <van-form>
          <van-field label-width="40" required label="账号" v-model="loginForm.username" name="username" clearable autocomplete="false"></van-field>
          <van-field label-width="40" required label="密码" v-model="loginForm.password" name="password" type="password" clearable autocomplete="false"></van-field>
        </van-form>
      </div>
    </van-dialog>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      active: 0,
      deviceAmount: 0,
      riderAmount: 0,
      userAmount: 0,
      taskAmount: 0,

      showLogin: false,
      loginForm: {
        username: '',
        password: ''
      },
      permissions: this.$store.getters.getProfile.permissions ? this.$store.getters.getProfile.permissions : []
    }
  },
  created() {
    this.$bus.$on('login', () => {
      this.showLogin = true
    })

    this.$http.get('profile').then(res => {
      if (res.code === 200) {
        this.$store.commit('setProfile', res.data)
        this.permissions = res.data.permissions
      }
    })
  },
  methods: {
    async doLogin(action, done) {
      if (action === 'confirm') {
        let res = await this.$http.post('login', this.loginForm)
        if (res.code === 200) {
          localStorage.setItem('token', res.data)
          window.location.reload()
          done()
        } else {
          this.$toast.fail({message: res.msg})
          done(false)
        }
      }
    }
  }
}
</script>

<style>
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: whitesmoke;
  margin-top: 60px;
}*/
.page {
  background-color: whitesmoke;
  height: calc(100% - 50px);
  width: 100%;
  position: absolute;
}

</style>
