import VueRouter from 'vue-router'

const router = new VueRouter({
    routes: [
        {path: '/', component: () => import('@/pages/cabinet')},
        {path: '/index', component: () => import('@/pages/cabinet')},
        {path: '/order', component: () => import('@/pages/orderList')},
        {path: '/cabinetCell', component: () => import('@/pages/cabinetCell')},
        {path: '/dataSheet', component: () => import('@/pages/dataSheet')},
        {path: '/deviceData', component: () => import('@/pages/deviceData')},
        {path: '/settings', component: () => import('@/pages/settings/settings')},
        {path: '/pushMessage', component: () => import('@/pages/settings/pushMessage')},
        {path: '/user/list', component: () => import('@/pages/user/userList')},
        {path: '/user/addUser', component: () => import('@/pages/user/addUser')},
        {path: '/rolePermission', component: () => import('@/pages/rolePermission')},
        {path: '/userCabinet', component: () => import('@/pages/userCabinet')},

        {path: '/mock', component: () => import('@/pages/mock/index')},
        {path: '/mock/index', component: () => import('@/pages/mock/cabinet')},
        {path: '/mock/rider', component: () => import('@/pages/mock/rider')},
        {path: '/mock/request', component: () => import('@/pages/mock/request')},
        {path: '/mock/requestList', component: () => import('@/pages/mock/requestList')},
        {path: '/mock/user', component: () => import('@/pages/mock/user')}
    ]
})
export default router
